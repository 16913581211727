<template>
  <div class="team-details py-8">
    <TeamAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="canModify"
        :model="team"
        :restore="restoreTeam"
        :has-restore="true"
        name="team"
        redirect="/teams"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="team-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <avatar-form
              :user="team"
              :disabled="(team && !team.isUpdatable) || !canModify"
              :upload="changeAvatar"
              @remove="teamAvatarRemoved"
            />

            <v-divider class="team-details__divider"></v-divider>

            <TeamForm :team="team" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Team
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import AvatarForm from '@/components/common/AvatarForm'
import TeamForm from '@/views/Home/Teams/components/TeamForm'
import TeamAppbar from '@/views/Home/Teams/components/TeamAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import ROLE from '@/utils/enums/Role'
import { includes } from 'lodash'

export default {
  name: 'TeamDetails',

  components: {
    ViewingTitle,
    AvatarForm,
    TeamAppbar,
    TeamForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      team: (state) => state.team.teamDetails,
      role: (state) => state.auth.role,
      user: (state) => state.auth.user,
    }),

    displayName() {
      return this.team ? this.team.full_name || this.team.email : null
    },

    isDisabled() {
      return this.team
        ? !!this.team.blocked_at || !!this.team.deleted_at
        : false
    },

    canModify() {
      switch (this.role) {
        case ROLE.SUPER_ADMIN:
          if (this.team.id === this.user.id) {
            return true
          } else {
            if (!includes(this.team.roles, ROLE.SUPER_ADMIN)) {
              return true
            }
          }

          return false
        case ROLE.ADMIN:
          if (this.team.id === this.user.id) {
            return true
          } else {
            if (
              !includes(this.team.roles, ROLE.SUPER_ADMIN) &&
              !includes(this.team.roles, ROLE.ADMIN)
            ) {
              return true
            }
          }

          return false
        default:
          return false
      }
    },
  },

  methods: {
    ...mapActions({
      getTeamDetails: 'team/getTeamDetails',
      changeAvatar: 'team/changeAvatar',
      restoreTeam: 'team/restoreTeam',
    }),

    ...mapMutations({
      clearTeamDetails: 'team/clearTeamDetails',
      teamAvatarRemoved: 'team/teamAvatarRemoved',
    }),

    async getTeam() {
      this.loading = true
      await this.getTeamDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getTeam()
  },

  destroyed() {
    this.clearTeamDetails()
  },

  watch: {
    $route() {
      this.getTeam()
    },
    team() {
      console.log(this.team)
    },
  },
}
</script>

<style lang="scss" scoped>
.team-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
